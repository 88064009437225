<template>
	<div class="projecet-container">
		<!-- <TopBar></TopBar> -->
		<div class="project-main">
			<div class="main-left">
				<div class="header-container">
					<div class="header-left">
						<el-button type="primary" @click="addSpace()" v-if="spaceAction.addprojecttype">{{$t('spaceType.createGroup')}}</el-button>
					</div>
					<div class="header-right">
							<el-input class="marginRight" @keyup.enter.native="search()" v-model="searchValue" :placeholder="$t('common.search')"></el-input>
							<div class="hdBtnBox">
								<el-button type="primary" icon="el-icon-search" @click="search()">{{$t('common.search')}}</el-button>
								<el-button type="primary" icon="el-icon-sort"  @click="sort(projectList,position)">{{$t('common.sort')}}</el-button>
							</div>
					</div>
				</div>
				<!-- 项目分类列表 -->
				<div class="project-box" v-if="projectOrCode==-1">
					<div class="item-container" v-for="item in projectList" :key="item.id" >
						<p class="item-title" :style="(spaceAction.deleteprojecttype||spaceAction.updateprojecttype)?'':'justify-content:center;'">
							<span style="font-size: 18px;font-weight: bold;color: transparent;" v-if="spaceAction.deleteprojecttype||spaceAction.updateprojecttype">...</span>
							<span class="item-name-box" :title="item.name">{{item.name}}</span>
							<el-dropdown trigger="click" v-if="spaceAction.deleteprojecttype||spaceAction.updateprojecttype">
								<span class="el-dropdown-link">
									<i class="el-icon-more"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item v-if="spaceAction.updateprojecttype" @click.native="setSpace(item)"><span class="el-icon-setting"></span>{{$t('common.configuration')}}</el-dropdown-item>
									<el-dropdown-item v-if="spacePermiss.isSpaceOwner||spacePermiss.isSmartAdmin" @click.native="deleteSpace(item)"><span class="el-icon-delete"></span>{{$t('common.delete')}}</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
							<span v-else> </span>
						</p>
						<div class="item-img" @click="intoProject(item)">
							<el-image v-if="true" src="../assets/default/group.png" alt fit="contain" style="width:190px;height:100px" :onerror="errorcoverImg" />
							<el-image v-else :src="item.coverPictureService" :onerror="errorcoverImg" fit="contain" style="width:190px;height:100px"></el-image>
							<!-- <p class="own-text1">{{item.fileSizeDto.sizeStr}}</p> -->
						</div>
					</div>
				</div>
				<!-- 编码库 -->
				<div class="code" v-if="projectOrCode==1">
					<Excel></Excel>
				</div>
				<!-- 资产参数 -->
				<div class="code"  v-if="projectOrCode==2">
					<TemplateCom :space="isSpace"></TemplateCom>
				</div>
			</div>
		</div>
		<el-dialog :title="addOrEdit=='add'?$t('spaceType.createGroup'):$t('spaceType.editGrouping')" :visible.sync="showDialog" width="30%" center :close-on-click-modal="false">
			<el-form ref="ruleForm1" :model="ruleForm1" :rules="rules1" label-width="100px" label-position="left" style="text-align: left;padding:20px">
				<el-form-item :label="$t('spaceType.componentName')" prop="ProjectTypeName">
					<el-input v-model="ruleForm1.ProjectTypeName" :placeholder="$t('spaceType.componentName')" />
				</el-form-item>
				<el-form-item :label="$t('spaceType.enterpriseCoding')" prop="Description">
					<el-input v-model="ruleForm1.Description" :placeholder="$t('spaceType.enterpriseCoding')" />
				</el-form-item>
				<el-form-item :label="$t('spaceType.cover')" v-if="addOrEdit=='add'">
					<el-upload ref="uploadAdd" :show-file-list="false" action
					    list-type="picture-card" :http-request="adduploadPicture"
						:on-change="addgetPicture" accept=".bmp,.jpg,.png,.jpeg,.BMP,.JPG,.PNG,.JPEG">
						<img v-if="addPictureUrl" :src="addPictureUrl" class="avatarPic">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
			</el-form>
			<div class="addBtn">
				<el-button type="danger" @click="showDialog=false">{{$t('common.cancel')}}</el-button>
				<el-button type="primary" @click="submit('ruleForm1')" v-if="addOrEdit=='add'">{{$t('common.confirmation')}}</el-button>
				<el-button type="primary" @click="submit('ruleForm1')" v-else>{{$t('common.save')}}</el-button>
			</div>
		</el-dialog>
		<!-- 编辑角色、添加角色 -->
		<el-dialog :title="addOrEdit=='add'?'添加权限':'编辑权限'" :visible.sync="addRoleDialog" width="30%" :close-on-click-modal="false">
			<el-form ref="ruleForm2" :model="ruleForm2" :rules="rules" label-width="100px" label-position="left" style="text-align: left;">
				<el-form-item label="角色名称" prop="userName">
					<el-input v-if="addOrEdit=='add'" v-model="ruleForm2.spaceRoleName" placeholder="用户名称" />
					<el-input v-else v-model="ruleForm2.spaceRoleName" placeholder="用户名称" />
				</el-form-item>
				<el-form-item label="角色">
					<el-tree ref="permissionTree" v-if="isShowTree" :data="allRole" @check="handleRoleChange" show-checkbox node-key="id"
					 :props="{children:'children',label: 'permissionName' }" :default-checked-keys="defaultCheckedKeys">
					</el-tree>
				</el-form-item>

			</el-form>
			<div style="text-align:right;">
				<el-button type="danger" @click="addRoleDialog=false">{{$t('common.cancel')}}</el-button>
				<el-button type="primary" @click="submitForm('ruleForm2')">{{$t('common.save')}}</el-button>
			</div>
		</el-dialog>

		<!-- 修改用户角色ID -->
		<el-dialog title="修改用户" :visible.sync="updateUserSpaceRole" width="30%" :close-on-click-modal="false">
			<div style="text-align:right;">
				<el-form ref="ruleForm3" :model="curFixData" :rules="fixUserRoleRules" label-width="100px" label-position="left" style="text-align: left;">
					<el-form-item>
						<el-input  v-model="curFixData.name" placeholder="用户名称" disabled />
					</el-form-item>

					<el-form-item label="角色" prop="spaceId">

						<el-select  v-model="curFixData.spaceId" placeholder="请选择">
							<el-option
							  v-for="item in SpaceRoleListHavePermiss "
							  :key="item.id"
							  :label="item.spaceRoleName"
							  :value="item.id">
							</el-option>
						 </el-select>
					</el-form-item>
				</el-form>
				<el-button type="danger" @click="updateUserSpaceRole=false">{{$t('common.cancel')}}</el-button>
				<el-button type="primary" @click="updateUserSpaceRoleAPI">{{$t('common.save')}}</el-button>
			</div>
		</el-dialog>
		<el-dialog title="添加组织" :visible.sync="addOrgDialog" width="30%" :close-on-click-modal="false">
			<div style="text-align:right;">
				<el-form label-width="100px" label-position="left" style="text-align: left;">
					<el-form-item>
						<el-input  v-model="orgName" placeholder="组织名称" />
					</el-form-item>
				</el-form>
				<el-button type="danger" @click="addOrgDialog=false">{{$t('common.cancel')}}</el-button>
				<el-button type="primary" @click="addOrgSubmit()">{{$t('common.save')}}</el-button>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="editDialog" :close-on-click-modal="false" title="上传封面图">
			<el-form label-width="80px" label-position="left">
				<el-form-item label="封面图" style="text-align:left">
					<el-upload ref="uploadAdd" :show-file-list="false" action
					    list-type="picture-card" :http-request="uploadPicture"
						:on-change="getPicture" accept=".bmp,.jpg,.png,.jpeg,.BMP,.JPG,.PNG,.JPEG">
						<img v-if="PictureUrlService" :src="PictureUrlService" class="avatarPic">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
			</el-form>
			<div style="text-align:center;">
				<el-button type="danger" @click="editDialog = false">{{$t('common.cancel')}}</el-button>
				<el-button type="primary" @click="changeImg">{{$t('common.confirmation')}}</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
  mapState, mapMutations
} from 'vuex'
import TopBar from '@/components/layout/TopBar.vue'
import Excel from '@/components/excel/netExcel.vue'
// import UserCom from "@/components/userCom.vue";
import TemplateCom from '../views/system/TemplateCom.vue'
import axios from 'axios'
import XLSX from 'xlsx'
import transformSheets from '../store/read_xlsx' // 导入转制函数
var formData = new FormData()
var addformData = new FormData()
export default {
  name: 'One',
  data () {
    return {
      position: false,
      addPictureUrl: '',
      editDialog: false,
      PictureUrlService: '',
      addOrEdit: 'add',
      projectOrCode: -1,
      curUserID: localStorage.getItem('USER_ID'),
      curUserStatu: 0,
      curUserOwnId: 0, // 企业拥有者id
      TemplateComPage: 0,
      errorImg: 'this.src="' + require('../assets/header.png') + '"',
      errorcoverImg: 'this.src="' + require('../assets/default/group.png') + '"',
      content: '', // 初始化数据
      err: '',
      SearchDataList: [],
      data: [],
      addOrgDialog: false,
      orgName: '',
      OrgParentID: 0,
      updateUserSpaceRole: false,
      fixUserRoleRules: {
        UserName: {
          required: true,
          message: '用户名不能为空',
          trigger: 'blur'
        },
        spaceId: {
          required: true,
          message: '请选择角色',
          trigger: 'change'
        }
      },
      curFixData: {
        name: '',
        spaceId: 0,
        userId: 0
      },
      isShowTree: false,
      addRoleDialog: false,
      addOrEdit: 'add',
      checkRole: [],
      allRole: [{
        permissionName: '全部权限',
        roleId: -1,
        children: []
      }],

      company: {

      },
      ruleForm2: {
        spaceRoleName: ''
      },
      showStandard: 1,
      addStandardVisible: false,
      copyStandardVisible: false,
      standardList: [],
      SpaceRoleList: [],
      SpaceRoleListHavePermiss: [],
      ruleFormAdd: {
        standardName: ''
      },
      spaceList: [],
      radio: '2',
      standardProject: [],
      rulesAdd: {
        standardName: {
          required: true,
          message: '请填写标准名称',
          trigger: 'blur'
        }
      },
      rules: {
        UserName: {
          required: true,
          message: '请填写用户名',
          trigger: 'blur'
        },
        juris: {
          required: true,
          message: '请选择所属项目',
          trigger: 'change'
        },
        SpaceRoleId: {
          required: true,
          message: '请选择空间角色',
          trigger: 'change'
        }
      },
      ruleFormCopy: {
        standardName: '',
        space: '',
        projectType: '',
        project: ''
      },
      defaultCheckedKeys: [],
      needCopyStandard: {},
      rulesCopy: {
        standardName: {
          required: true,
          message: '请填写标准名称',
          trigger: 'blur'
        },
        space: {
          required: true,
          message: '请选择企业',
          trigger: 'change'
        },
        projectType: {
          required: true,
          message: '请选择项目分组',
          trigger: 'change'
        },
        project: {
          required: true,
          message: '请选择项目',
          trigger: 'change'
        }
      },

      ruleForm: {
        UserName: '',
        SpaceRoleId: '',
        ProjectId: ''
      },

      projectList: [],
      userList: [],
      searchValue: '',
      imgUrl: require('../assets/project.png'),
      showDialog: false,
      ruleForm1: {
        ProjectTypeName: '',
        Description: ''
      },
      rules1: {
        ProjectTypeName: [{
          required: true,
          message: '分组名不能为空',
          trigger: 'blur'
        }, {
          validator: this.$utils.nameCheck,
          trigger: 'change'
        }]
      },
      projectTypeOrCode: 1,
      addSpaceName: '',
      userPer: '',
      isSpace: 'space',
      roleListTemp: [],
      allOrg: [],
      viewTitle: '',
      userPermiss: {}
    }
  },
  props: {
    msg: String
  },
  mounted () {
    this.init()
    this.viewTitle = localStorage.getItem('enterpriseCode')
    // this.initUserInfo()
  },
  directives: {
    focus: {
      update: function (el, { value }) {
        if (value) {
          el.children[0].focus()
        }
      }
    }
  },
  computed: {
    ...mapState('spaceAction', {
      spaceAction: 'spaceAction'
    }),
    ...mapState('spacePermiss', {
      spacePermiss: 'spacePermiss'
    })
  },
  methods: {
    ...mapMutations('project', {
      initProjectType: 'initProjectType'
    }),
    ...mapMutations('spacePermiss', {
      initSpacePermiss: 'initSpacePermiss'
    }),
    // ...mapMutations("user", {
    //   initUserInfo: "initUserInfo"
    // }),
    // initUserInfo(){
    // 	this.$axios.get("/api/User/LoginByToken").then(res => {
    // 		const userAllInfoDto = res;
    // 	   this.initUserInfo(userAllInfoDto);
    // 	})
    // },
    updateUserSpaceRoleAPI () {
      this.$axios.get(`/api/user/updatespaceroleid?userId=${this.curFixData.userId}&spaceRoleId=${this.curFixData.spaceId}`).then(res => {
        if (res == '更新成功') {
          for (var key in this.userList) {
            if (this.userList[key].userId == this.curFixData.userId) {
              var findValue = this.SpaceRoleList.find(item => {
                return item.id == this.curFixData.spaceId
              })
              this.userList[key].spaceRoleList[0] = findValue
            }
          }
          this.updateUserSpaceRole = false
          this.$message({
            type: 'success',
            message: '修改成功'
          })
        }
      })
    },
    toOwnProjectType () {
      this.$router.push('/ownProjectType')
    },
    getSpaceUser () {
      // 获取空间用户
      this.$axios.get('/api/User/GetSpaceUserList').then(res => {
        this.userList = res
        var curUser = this.userList.findIndex(n => n.userId == this.curUserID)
        var curOwnUser = this.userList.findIndex(n => n.spaceOwnUser == 1)
        this.curUserOwnId = this.userList[curOwnUser].userId
        if (curUser > -1) {
          if (this.userList[curUser].spaceRoleList.length > 0) {
            this.curUserStatu = this.userList[curUser].spaceRoleList[0].statu
          }
        } else {
          // 能登陆进来，并且空间用户列表中没有此用户，说明此用户为超级管理员
          this.curUserStatu = 100
        }
 				})
 			},
			 spaceRole () {
      this.showStandard = 2
      // 获取空间权限
      this.$axios.get('/api/Permission/GetSpacePermission').then(res => {
        this.allRole[0].children = res
      })
      // 空间角色
      this.$axios.get('/api/SpaceRole/GetAllSpaceRole').then(res => {
        // console.log(res)
        this.SpaceRoleList = res
        this.roleListTemp = res
      })
    },
    init () {
      console.log('spaceAction', this.spaceAction)
      var _this = this
      console.log(localStorage.eleSpaceId)
      this.$axios.get('/api/Project/GetProjectBySpaceId?spaceId=' + localStorage.eleSpaceId).then(res => {
        // return
        var projectList = res
        this.pulluserprojectList = []
        // 获取项目
        res.forEach(p => {
          if (p.projectListDto && p.projectListDto.length > 0) {
            _this.standardProject = _this.standardProject.concat(p.projectListDto)
          }
        })
        for (var i = 0; i < projectList.length; i++) {
          projectList[i].positionNum = i + 1
          projectList[i].projectTypeId = projectList[i].id
          projectList[i].id = 'card' + projectList[i].id
          projectList[i].name = projectList[i].projectTypeName
          if (projectList[i].projectListDto && projectList[i].projectListDto.length > 0) {
            this.pulluserprojectList = this.pulluserprojectList.concat(projectList[i].projectListDto)
          }
        }
        this.SearchDataList = projectList
        this.projectList = this.SearchDataList
        localStorage.setItem('projectList', JSON.stringify(this.projectList))
      })

      // 获取用户权限（超级管理员，管理员）
      this.$axios.get('/api/Project/judgeUserPermissions').then(res => {
        console.log(res)
        this.userPer = res
      })
      this.$axios.get(`/api/project/judgeuserpermiss?SpaceId=${localStorage.getItem('eleSpaceId')}&UserId=${localStorage.getItem('USER_ID')}`).then(res => {
        console.log(res)
        this.userPermiss = res
        // localStorage.setItem('userPermiss',JSON.stringify(res))
        this.initSpacePermiss(res)
      })
    },
    submitForm (ruleForm2) {
      // 新的新的新的新的新的新的新的新的新的新的
      var _this = this
      this.$refs[ruleForm2].validate(valid => {
        if (valid) {
          // 添加
          // return
          var roleAdd = {}
          roleAdd.spaceRoleName = _this.ruleForm2.spaceRoleName
          roleAdd.rolePermissionIds = []
          if (_this.addOrEdit == 'add') {
            _this.checkPer.forEach(element => {
              if (element.id > -1) {
                roleAdd.rolePermissionIds.push(element.id)
              }
            })
            _this.$axios.post('/api/spacerole/addspacerole', roleAdd).then(res => {
              var role = {}
              role.spaceRoleName = _this.ruleForm2.spaceRoleName
              role.creationTimeStr = _this.getFullTime()
              role.roleId = res.id
              role.rolePermissionDtos = []
              _this.checkPer.forEach(element => {
                if (element.id > -1) {
                  var per = {}
                  per.permissionName = element.permissionName
                  per.permissionId = element.id
                  per.id = element.id
                  role.rolePermissionDtos.push(per)
                }
              })
              _this.roleListTemp.push(role)
              _this.$message({
                type: 'success',
                message: '添加角色成功'
              })
              _this.checkPer = []
              _this.isShowTree = false
              _this.defaultCheckedKeys = []
              setTimeout(() => {
                _this.isShowTree = true
              }, 100)
              _this.addRoleDialog = false
              _this.ruleForm2 = {
                spaceRoleName: ''
              }
            })
          } else {
            const data = {
              id: _this.ruleForm2.id,
              spaceRoleName: _this.ruleForm2.spaceRoleName,
              roleAddPermissionIds: [],
              roleDeletePermissionIds: []
            }
            // 1.生成添加的数据
            // 和ruleForm2种的对比，没有就代表是新增的
            var oldPer = _this.ruleForm2.rolePermissionDtos
            var newPer = _this.checkPer
            oldPer.forEach(element => {
              // 在新选择的所有权限中 如果没有找到原来的，说明是被删除的
              var index = newPer.findIndex(n => n.id == element.permissionId)
              if (index == -1) {
                data.roleDeletePermissionIds.push(element.permissionId)
              }
            })
            // }
            newPer.forEach(element => {
              // 在原有的所有权限中 如果没有找到的项，说明是新增的
              var index = oldPer.findIndex(n => n.permissionId == element.id)
              if (index == -1 && element.id != -1) {
                data.roleAddPermissionIds.push(element.id)
              }
            })
            // console.log(data)
            // 2.生成修改的数据
            // 提交数据
            this.$axios.post('/api/spacerole/updatespacerole', data).then(res => {
              // console.log(res)
              // 更新成功之后需要在前端更新数据
              // _this.SpaceRoleList
              var index = _this.roleListTemp.findIndex(item => {
                return item.id == data.id
              })
              // console.log(_this.SpaceRoleList[index])
              // var newObj = _this.$utils.deepClone(_this.SpaceRoleList[index])
              var newObj = JSON.parse(JSON.stringify(_this.roleListTemp[index]))
              // console.log(newObj)
              // console.log()
              var newRoleList = JSON.parse(JSON.stringify(_this.checkPer))
              var arr = []
              newRoleList.forEach(element => {
                var p = {}
                p.permissionId = element.id
                p.id = element.id
                p.permissionName = element.permissionName
                arr.push(p)
              })
              _this.$message({
                type: 'success',
                message: '修改成功'
              })
              newObj.rolePermissionDtos = arr
              _this.roleListTemp[index] = newObj
              _this.SpaceRoleList = JSON.parse(JSON.stringify(_this.roleListTemp))
              _this.addRoleDialog = false
              // console.log(_this.SpaceRoleList[index])
            })
          }
        }
      })
    },
    addOrg (id) {
      this.addOrgDialog = true
      this.OrgParentID = id
    },
    addOrgSubmit () {
      if (this.orgName == '') {
        this.$message({
          type: 'warning',
          message: '请输入组织名称'
        })
        return
      }
      if (this.orgName == '默认组织') {
        this.$message({
          type: 'warning',
          message: '‘默认组织’为企业自带组织'
        })
        return
      }
      this.$axios.get('/api/Organization/AddOrganiz?OrganizName=' + this.orgName + '&OrgParentID=' + this.OrgParentID).then(res => {
        this.$message({
          type: 'success',
          message: '添加成功'
        })
        this.orgName = ''
        this.addOrgDialog = false
        this.showOrg()
      })
    },
    showOrg () {
      this.showStandard = 4
      this.$axios.get('/api/Organization/GetOrganizList').then(res => {
        this.allOrg = res
      })
    },
    sort (arr, pos) {
      if (pos) {
        arr.sort((a, b) => a['nickName'] < b['nickName'] ? 1 : -1)
      } else {
        arr.sort((a, b) => a['nickName'] > b['nickName'] ? 1 : -1)
      }
      this.position = !this.position
    },

    // 添加角色
    addRole () {
      this.addOrEdit = 'add'
      this.ruleForm2 = {
        spaceRoleName: ''
      }
      this.isShowTree = false
      this.checkPer = []
      setTimeout(() => {
        this.isShowTree = true
      }, 100)
      this.addRoleDialog = true
    },
    deletespacerole (role) {
      // 删除角色
      // console.log(role)
      this.$confirm('此操作将删除角色，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get(`/api/spacerole/deletespacerole?roleId=${role.id}`).then(res => {
          if (res == '删除成功') {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
          }
          var index = this.SpaceRoleList.findIndex(item => {
            return item.id == role.id
          })
          this.SpaceRoleList.splice(index, 1)
        })
      })
    },
    // 编辑角色
    updateRole (role) {
      this.addOrEdit = 'edit'
      this.isShowTree = false
      this.defaultCheckedKeys = []
      this.checkPer = role.rolePermissionDtos
      this.checkPer.forEach(element => {
        element.id = element.permissionId
      })
      var key = []
      this.ruleForm2 = this.$utils.deepClone(role)
      this.company = this.$utils.deepClone(role)
      role.rolePermissionDtos.forEach(e => {
        key.push(e.id)
      })
      this.defaultCheckedKeys = key
      setTimeout(() => {
        this.isShowTree = true
      }, 100)
      this.addRoleDialog = true
    },
    handleRoleChange (one) {
      // this.allRole
      var keys = this.$refs.permissionTree.getCheckedKeys()
      var ischeck = false
      if (keys.indexOf(one.id) == -1) {
        ischeck = false
      } else {
        ischeck = true
      }
      var all = []
      // 生成一个没有层级结构的数组，用于后续查找
      function newAll (arr) {
        for (var item of arr) {
          all.push(item)
          if (item.children.length != 0) {
            newAll(item.children)
          }
        }
      }
      newAll(this.allRole[0].children)

      var key = []
      // 全选的方法
      function findChecked (obj) {
        // if(one)
        key.push(obj.id)
        if (obj.children.length != 0) {
          for (var item of obj.children) {
            findChecked(item)
          }
        }
      }
      // 随机选中某一个的方法
      function checkOne (obj) {
        key.push(obj.id)
        if (obj.parentID) {
          var newobj = all.filter(item => {
            return item.id == obj.parentID
          })
          checkOne(newobj[0])
        }
      }

      function cancel (obj) {
        let length = obj.children.length
        if (length != 0) {
          for (let item of obj.children) {
            let index = keys.findIndex(item2 => {
              return item2 == item.id
            })
            if (index != -1) {
              keys.splice(index, 1)
            }
            cancel(item)
          }
        }
      }
      if (ischeck) {
        console.log('选中')
        // 是选中时
        // 是全选的时候
        if (one.id == -1) {
          findChecked(one)
          keys = key
        } else {
          // 如果不是全选时
          // 如果存在父元素，要把父元素选上、全选除外,还要把父元素的父元素选上
          if (one.parentID) {
            checkOne(one)
            keys.push(...key)
          }
          // 如果没有父元素，就把自己选上
        }
      } else {
        // 取消时需要判定是否有子元素，如果有，子元素也需要被取消
        // 如果是全部权限那个，就直接置为空
        console.log('取消')
        if (one.id == -1) {
          keys = []
        } else {
          cancel(one)
        }
      }
      this.$refs.permissionTree.setCheckedKeys(keys)
      keys = keys.filter(item => {
        return item != -1
      })
      this.checkPer = []
      for (var i = 0; i < this.allRole[0].children.length; i++) {
        this.selectPer(this.allRole[0].children[i], keys)
      }
    },
    selectPer (per, keys) {
      if (keys.findIndex(n => n == per.id) > -1) {
        this.checkPer.push(per)
      }
      if (per.children && per.children.length > 0) {
        for (var i = 0; i < per.children.length; i++) {
          this.selectPer(per.children[i], keys)
        }
      }
    },
    intoProject (project) {
      console.log(project)
      localStorage.setItem('enterpriseCode', project.description)
      localStorage.setItem('eleProjectTypeId', project.projectTypeId)
      this.initProjectType(this.projectList)
      this.$router.push('/project')
    },

    showAddDialog () {
      this.addStandardVisible = true
      this.data = []
    },
    showCopyDialog (standard) {
      this.ruleFormCopy.project = ''
      this.ruleFormCopy.projectType = ''
      this.ruleFormCopy.standardName = ''
      this.needCopyStandard = standard
      this.copyStandardVisible = true
    },
    projectTypeChange (projectTypeId) {
      var index = this.projectList.findIndex(n => n.projectTypeId == projectTypeId)
      this.standardProject = this.projectList[index].projectListDto
      this.ruleFormCopy.project = ''
    },
    startDrag (event, id) {
      console.log(event)
      console.log(id)
    },
    swicthPosition (oldPositon, newPositon, originItem) {
      console.log(oldPositon) // 卡片原来的位置号码
      console.log(newPositon) // 卡片需要交换的位置号码
      console.log(originItem) // 卡片交换完成后的数据
    },
    finishDrag (oldPositon, newPositon, originItem) {
      console.log(oldPositon) // 卡片原来的位置号码
      console.log(newPositon) // 卡片需要交换的位置号码
      console.log(originItem) // 卡片交换完成后的数据
    },
    addSpace () {
      this.ruleForm1 = {
        ProjectTypeName: '',
        Description: ''
      }
      addformData = new FormData()
      this.addPictureUrl = ''
      this.addOrEdit = 'add'
      this.showDialog = true
    },
    // 判断addformData是否有该属性
    haveAttu (name, value) {
      if (addformData.get(name) == null) {
        addformData.append(name, value)
      } else {
        addformData.set(name, value)
      }
    },
    submit (ruleForm1) {
      this.$refs[ruleForm1].validate(valid => {
        if (valid) {
          if (this.addOrEdit == 'add') {
            this.haveAttu('ProjectTypeName', this.ruleForm1.ProjectTypeName)
            this.haveAttu('Description', this.ruleForm1.Description)
						    this.$axios.post('/api/Project/AddProjectType', addformData).then(res => {
              console.log(res)
              this.$message({
								    type: 'success',
								    message: '新建成功'
              })
              this.showDialog = false
              this.init()
						    })
          } else {
            this.$axios.post('/api/Project/UpdateProjectType', this.ruleForm1).then(res => {
              this.$message({
						    	    type: 'success',
						    	    message: '修改成功'
              })
              var id = this.ruleForm1.Id
              var index = this.projectList.findIndex(n => n.projectTypeId == id)
              this.projectList[index].projectTypeName = this.ruleForm1.ProjectTypeName
              this.projectList[index].name = this.ruleForm1.ProjectTypeName
              this.projectList[index].description = this.ruleForm1.Description
              this.showDialog = false
            })
          }
        }
      })
    },
    search () {
      var value = this.searchValue
      if (value == '') {
        this.projectList = this.SearchDataList
      } else {
        this.projectList = this.SearchDataList.filter(res => {
          return res.name.indexOf(value) != -1
        })
      }
    },
    getFullTime () {
      let date = new Date() // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + ''
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
      let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
      let h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours())
      let m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes())
      let s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds())
      console.log()
      return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
    },
    deleteSpace (item) {
      console.log(item)
      this.$confirm('项目分组被删除时，该分组下所有项目也将被删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get('/api/Project/DeleteProjectType?projectTypeId=' + item.projectTypeId).then(res => {
          this.$message({
						    type: 'success',
						    message: res
					    })
          var index = this.SearchDataList.findIndex(n => n.id == item.id)
          this.SearchDataList.splice(index, 1)
        })
      })
    },
    setSpace (item) {
      console.log(item)
      this.ruleForm1 = {
        Id: item.projectTypeId,
        ProjectTypeName: item.projectTypeName,
        Description: item.description
      }
      this.addOrEdit = 'edit'
      this.showDialog = true
    },

    uploadImg (item) {
      this.ruleForm1 = {
        Id: item.projectTypeId,
        ProjectTypeName: item.projectTypeName,
        Description: item.description
      }
      this.editDialog = true
      this.PictureUrlService = item.coverPictureService
      formData = new FormData()
      if (this.PictureUrlService) {
        var index = this.PictureUrlService.indexOf('Doc/Project')
        var url = this.PictureUrlService.substring(index, this.PictureUrlService.length)
        formData.append('CoverPicture', url)
      }
    },
    uploadPicture (file) {
      formData.append('FormFile', file.file)
    },
    getPicture (file, fileList) {
      this.PictureUrlService = file.url
    },
    adduploadPicture (file) {
      addformData.append('FormFile', file.file)
    },
    addgetPicture (file, fileList) {
      this.addPictureUrl = file.url
    },
    changeImg () {
      if (this.PictureUrlService) {
        var id = this.ruleForm1.Id
        formData.append('Id', this.ruleForm1.Id)
        formData.append('statu', 2)
        this.$axios.post('/api/Project/UpdateCoverPicture', formData).then(res => {
          this.$message({
						    type: 'success',
						    message: '上传成功'
					    })
          var index = this.projectList.findIndex(n => n.projectTypeId == id)
          this.projectList[index].coverPictureService = res.headPortrait
          this.editDialog = false
        })
      }
    }
  },

  components: {
    // cardDragger,
    TopBar,
    Excel,
    TemplateCom
    // UserCom
  }
}
</script>

<style scoped lang="less">
	.projecet-container {
		display: flex;
		justify-content: space-between;
		.el-button--primary{
			background-color: #475065;
			border: none;
		}
		.project-main {
			display: flex;
			// height: calc(100vh - 60px);
			// width: calc(100vw - 130px);
			min-height: calc(100vh - 80px);
			height: 877px;
			min-width: calc(100vw - 136px);
			width: 1760px;
			background-color: #e8e8e8;
			padding: 15px 25px;
			box-sizing: border-box;

			.main-left {
				width: 100%;
				// height: calc(100vh - 90px);
				// width: 76%;
				min-height: calc(100vh - 90px);
				// background-color: #000000;
				.header-container {
					display: flex;
					// height: 70px;
					justify-content: space-between;
					align-items: center;
					padding: 0 20px 0 0;
					font-size: 18px;
					font-family: PingFangSC-Semibold, PingFang SC;
					font-weight: 600;
					color: #343434;
					margin-bottom: 10px;

					.header-right {
						display: flex;
						height: 40px;

						.hdBtnBox {
							display: flex;
							margin-left: 10px;

							.marginRight {
								margin-right: 5px;
							}

							.search {
								background-color: #475065;
								border: none;
							}
						}
					}
				}

				.project-box {
					// height: calc(100vh - 140px);
					height: calc(100vh - 140px);
					min-height: 797px;
					overflow-y: auto;
					display: flex;
					flex-wrap: wrap;
					justify-content: flex-start;
					align-content: flex-start;
					// background-color: #000000;
					// padding-right: 20px;
					.addSpace {
						padding-top: 100px;
						box-sizing: border-box;
						width: 280px;
						height: 268px;
						background: #FFFFFF;
						box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
						border-radius: 2px;
						cursor: pointer;
						margin: 10px 20px 10px 0;
						span{
							font-size: 44px;
							color: #d8d8d8;
						}
						p{
							font-size: 18px;
							font-family: PingFangSC-Medium, PingFang SC;
							font-weight: 500;
							color: #444444;
							line-height: 25px;
							margin-top: 5px;
						}
					}
					.item-container{
						position: relative;
						margin: 10px 20px 10px 0;
						width: 190px;
						height: 145px;
						background: #FFFFFF;
						box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
						border-radius: 2px;
						.item-title{
							display: flex;
							align-items: center;
							justify-content: space-between;
							height: 42px;
							background: #F7F7F8;
							box-sizing: border-box;
							padding: 0 20px;
							border-top: 4px solid #414141;
							font-weight: 500;
							color: #444444;
							.item-name-box{
								// background-color: #000000;
								max-width: 200px;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
						}
						.item-img{
							cursor: pointer;
							.own-text1 {
								position: absolute;
								bottom: 5px;
								right: 5px;
								background-color: #2f374a;
								color: #fff;
								padding:0 5px;
							}
						}

					}
				}
			}
		}

		.fontCss {
			color: #e2d300;
			margin-left: 5px;
		}

		.el-dialog__header {
			text-align: left;
			background-color: #f8f8f9;
		}

		.el-form-item__content {
			margin: 0 !important;
		}

		.el-dialog__body {
			padding: 10px 20px;
		}

		.el-dialog {
			p {
				text-align: left;
				height: 40px;
				line-height: 40px;
			}

			.el-button--primary {
				background-color: #475065;
				border: none;
			}
		}

		.el-form-item__label {
			// width: 0;
			text-align: left;
		}

		.inline-box {
			display: flex;
			justify-content: space-between;

			.el-form-item {
				width: 45%;
			}
		}

	}

	.pointer {
		cursor: pointer;
	}

	.add {
		display: flex;
		padding: 10px;

		p {
			margin-top: 13px;
			margin-right: 10px;
		}

		.el-input {
			width: 80%;
		}
	}

	.addBtn {
		margin-left: 64%;
		margin-top: 20px;
	}

	.template {
		display: flex;
		height: 650px;
		padding: 20px;
		text-align: left;
		.template-item {
			flex: 1;
			border-radius: 8px;
			padding: 10px;
			// box-shadow: 0px 2px 15px 0px rgb(184, 183, 183);
		}
		.template-left {
			margin-right: 20px;
			.template-main {
			display: flex;
			height: calc(100vh - 195px);
			overflow-y: auto;

			.tree-container {
				width: 80%;
				margin-right: 25px;
				height: 65vh;
				background-color: #ffffff;
				border-radius: 8px;
				overflow: hidden;

				.title {
					text-align: left;
					// padding: ;
					height: 47px;
					line-height: 47px;
					padding: 0 15px;
					font-size: 18px;
					font-family: PingFangSC-Semibold, PingFang SC;
					font-weight: 600;
					color: #4c4c4c;

					background: #f8f8f9;

					.el-tree-node__expand-icon {
						display: none !important;
					}
				}

				.treeAdd {
					height: 45px;
					overflow-y: auto;

					.tree-add,
					.tree-one {
						cursor: pointer;
						height: 35px;
						line-height: 35px;
						text-align: left;
						padding-left: 40px;
					}
					.tree-add {
						height: 43px;
						line-height: 43px;
						padding: 0 10px;
						cursor: pointer;
						font-size: 18px;
						color: #868686;
						background-color: #f6f6f6;

						span {
							font-size: 14px;
							margin-right: 130px;
						}
					}
					.tree-addPer {
						padding-left: 50px;
					}
				}
				.tree {
					height: calc(100% - 92px);
					overflow-y: auto;

					.tree-standard {
						.span1 {
							white-space:nowrap;
							text-overflow:ellipsis;
							overflow:hidden;
							text-align: left;
							width: 160px;
							display:-moz-inline-box;
							display:inline-block;
						}

					}

					.tree-add,
					.tree-one {
						cursor: pointer;
						height: 35px;
						line-height: 35px;
						text-align: left;
						padding-left: 40px;

					    .shareStan {
					    	float: right;
					    	margin-right: 10px;
					    	color: #0094ce;
					    	display: none;
					    }

					}

					.tree-one:hover .shareStan{
						display: inline;
					}

					.edit,
				    .delete {
					display: none;
				    	color: #0094ce;
				    }

				    .edit {
				    	margin-right: 10px;
				    }

				    .tree-one-child:hover .edit,
				    .tree-one-child:hover .delete {
				    	display: inline;
				    }

					.tree-one {
						background: url("../assets/file.png") no-repeat 10px 8px;
					}

					.tree-one-child{
						margin-left:20px;

						span {
							white-space:nowrap;
							text-overflow:ellipsis;
							overflow:hidden;
							text-align: left;
							width: 160px;
							display:-moz-inline-box;
							display:inline-block;
						}
						.right {
							float: right;
							margin-right: 5px;
							margin-top: 8px;
						}
						.el-input{
							width: 92%;
						}
						.el-input__inner{
							height: 32px;
						}
					}

					.tree-one-father .tree-one {
						padding-left: 60px;
						background: url("../assets/menu.png") no-repeat 30px 8px;
					}

					.tree-hover {
						background-color: #d8d8d8;
					}

					.tree-add {
						height: 43px;
						line-height: 43px;
						padding: 0 30px;
						cursor: pointer;
						font-size: 18px;
						color: #868686;
						background-color: #f6f6f6;

						span {
							font-size: 14px;
						}
					}
				}
			}

			.list-container {
				width: calc(100% - 300px);
				height: calc(100vh - 220px);
				overflow-y: auto;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-around;
				align-content: flex-start;
				.edit,
				.delete {
					position: absolute;
					top: 23px;
					right: 33px;
					display: none;
					color: #0094ce;
				}

				.edit {
					right: 66px;
				}

				.list-one:hover .edit,
				.list-one:hover .delete {
					display: inline;
				}

				.list-add-one {
					display: flex;
					justify-content: center;
					align-items: center;

				}

				.list-add-one .i1 {
					font-size: 51px;
					color: #d8d8d8;

				}

				.zhanwei {
					width: 438px;
					height: 139px;
				}

				.list-add-one,
				.list-one {
					position: relative;
					// float: left;
					margin: 25px 5px 10px 5px;
					width: 438px;
					height: 139px;
					padding: 23px 33px;
					background-color: #fff;
					box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
					border-radius: 8px;
					box-sizing: border-box;
					text-align: left;

					.title {
						padding-left: 25px;
						background: url("../assets/one.png") no-repeat;
						font-size: 14px;
						height: 18px;
						line-height: 18px;
						font-weight: 500;
						color: #5f5f5f;
						margin-bottom: 28px;

						.el-input{
							width: 70%;
						}
						.el-input__inner{
							height: 24px;
						}
					}

					.msg-one {
						font-size: 12px;
						color: #5f5f5f;
						margin-bottom: 12px;
					}
				}
			}

			.list-container-noOne {
				position: relative;
				background: #fff url("../assets/noOne.png") no-repeat center center;
				box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
				border-radius: 8px;
			}

			.list-container-noOne::before {
				position: absolute;
				content: "暂无内容";
				top: 63%;
				left: 47.5%;
				color: #868686;
			}
		}
		}
		.template-right {
		}
	}
</style>
